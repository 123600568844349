<template>
  <div>
    <b-row
      align-h="center"
      class="mt-3"
    >
      <b-col lg="8">
        <b-card header="Edit User Roles">
          <b-form>
            <role-table-select
              :roles="allEntityRoles"
              @assignRole="assignUserRole"
              @revokeRole="revokeUserRole"
            />
            <back />
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import RoleTableSelect from '@/common/components/Permissions/RoleTableSelect.vue'
import entityRoles from '@/common/compositions/permissions/getEntityRoles'
import userRoles from '@/common/compositions/permissions/getUserRolesInEntity'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'EditUserRole',
  components: {
    RoleTableSelect,
    Back,
  },
  data() {
    return {
      allEntityRoles: [],
    }
  },
  computed: {
    username: {
      get() {
        return this.$router.currentRoute.params.username
      },
    },
    entityId: {
      get() {
        return this.$router.currentRoute.params.entityId
      },
    },
  },
  created() {
    this.resolveAllRolesPromises()
  },
  setup() {
    const {
      getUserRolesInEntity,
    } = userRoles()

    const { getEntityRoles } = entityRoles()
    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      successfulOperationAlert,
      showErrors,
      getEntityRoles,
      getUserRolesInEntity,
    }
  },
  methods: {
    resolveAllRolesPromises() {
      Promise.all([this.getEntityRoles(this.entityId), this.getUserRolesInEntity(this.username, this.entityId)]).then(res => {
        const entityRolesArray = res[0]
        const userRolesArray = res[1]
        this.markUserRolesAsSelected(userRolesArray, entityRolesArray)
      })
    },
    markUserRolesAsSelected(userRolesArray, entityRolesArray) {
      entityRolesArray.forEach((entityRole, entityRoleIndex) => {
        if (userRolesArray.some(ele => ele.id === entityRolesArray[entityRoleIndex].id)) {
          entityRolesArray[entityRoleIndex].vgtSelected = true
        }
      })

      this.allEntityRoles = entityRolesArray
    },
    assignUserRole(roleId) {
      this.$portalUsers.post(`v1/user-assign-role/${this.username}/${roleId}`, {
        entity: this.entityId,
      }).then(() => {
        this.successfulOperationAlert('User is assigned to role successfully')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    revokeUserRole(roleId) {
      this.$portalUsers.delete(`v1/user-revoke-role/${this.username}/${roleId}`, {
        data: {
          entity: this.entityId,
        },
      }).then(() => {
        this.successfulOperationAlert('User role is revoked successfully')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">
</style>
