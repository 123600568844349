import axiosInstances from '@/libs/axios-instances'

export default function userRoles() {
  const getUserRolesInEntity = (username, entityId) => axiosInstances.portalUsers.get(`v1/entity/member/role/${username}/${entityId}`).then(res => {
    const { roles } = res.data.data
    return roles
  })

  return {
    getUserRolesInEntity,
  }
}
