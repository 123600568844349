<template>
  <div class="mb-2">
    <!-- table -->
    <vue-good-table
      ref="my-table"
      :columns="columns"
      :rows="roles"
      :select-options="{
        enabled: true,
        disableSelectInfo: true, // disable the select info panel on top
      }"
      @on-row-click="rowClick"
    >
      <template
        #search-row="props"
      >
        <span class="text-capitalize">
          {{ props.row.label }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
  },
  props: {
    roles: { type: Array, default: () => [] },
  },
  data() {
    return {
      columns: [
        {
          label: 'Title',
          field: 'title',
          formatFn: this.capitalizeString,
        },
      ],
    }
  },
  methods: {
    capitalizeString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    rowClick(selectedRole) {
      const roleId = selectedRole.row.id
      const isRoleSelected = selectedRole.row.vgtSelected
      if (isRoleSelected) this.$emit('assignRole', roleId)
      else this.$emit('revokeRole', roleId)
    },
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/vue/libs/vue-good-table.scss";
.green-border{
  border: 4px solid rgb(67, 168, 67) !important;
}
</style>
